.statementaccount {
    display: block;
    padding: 10px;
}

.statementaccount_name {
    margin-top: 10px;
}

.statementaccount_balance {
    font-weight: 600;
    text-align: right;
    margin-right: 5px;
}