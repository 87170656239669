@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinnercontainer{
    margin-top: 8px;
    margin-bottom: 4px;
}

.spinner {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    animation: rotate 1s linear infinite;
}