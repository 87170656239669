.title {
    background-color: #ffde96;
    color: #000000;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 10px;
}

.title_text {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
}