.settings_container {
    max-width: 600px;
    margin: 0 auto;
}

.settings_label {
    display: block;
    margin-top: 10px;
    font-weight: bold;
}

.settings_textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    resize: none;
}

.settings_input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    box-sizing: border-box;
}

.settings_inputimagecontainer {
    margin-top: 10px;
}

.settings_inputimage {
    display: none;
}

.settings_uploadbutton {
    padding: 10px 20px;
    background-color: #000000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.settings_uploadbutton:hover {
    color: black;
    background-color: #ffde96;
}

.settings_imagecontainer {
    margin-top: 10px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.settings_removebutton {
    background-color: rgba(255, 255, 255, 0.8);
    color: #555;
    border: none;
    cursor: pointer;
    padding: 8px;
    font-size: 14px;
    line-height: 1;
    border-radius: 6px;
    transition: background-color 0.3s, color 0.3s;
}

.settings_removebutton:hover {
    background-color: rgba(211, 47, 47, 0.8);
    color: #fff;
}

.settings_buttonscontainer {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}

.settings_button {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    background-color: #e0e0e0;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 10px;
}

.settings_buttontext {
    margin-left: 10px;
    font-weight: bold;
    font-size: 16px;
}

.startdate,
.projectdetails,
.profile {
    text-align: center;
}