.projects {
    text-align: center;
    background-color: #f2f8ff;
    width: 100%;
}

.projects_logo {
    height: 100px;
}

.projects_header {
    display: flex;
    flex-direction: row;
    margin-left: 10%;
    margin-right: 10%;
    align-items: center;
}

.projects_headersection {
    flex: 1;
}

.projects_options {
    display: flex;
    flex-direction: row;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 10px;
}

.projects_optionssection {
    flex: 1;
}

.projects_optionstitle {
    font-weight: 700;
    color: rgb(49, 49, 49);
}

.projects_addbutton {
    background: #428bca;
    color: #fff;
    padding: 8px 20px;
    font-weight: 700;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
    border: none;
}

.projects_addbutton:hover {
    background: #0056b3;
}

.projects_removebutton {
    background: #d9534f;
    color: #fff;
    padding: 8px 20px;
    font-weight: 700;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
    border: none;
}

.projects_removebutton:hover {
    background: #c82333;
}

.projects_backupbutton {
    background: #000000;
    color: #fff;
    padding: 8px 20px;
    font-weight: 700;
    border: 1px solid black;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
    border: none;
}

.projects_backupbutton:hover {
    background: #4d4d4d;
}

.projects_seperator {
    border-bottom: 1px solid grey;
    margin-top: 20px;
}

.projects_projectscontainer {
    margin-right: 25%;
    margin-left: 25%;
}

.projects_projectbutton {
    padding: 15px;
    display: flex;
    flex-direction: row;
}

.projects_projecttext {
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    color: rgb(111, 111, 255);
}

.projects_projecttext:hover {
    color: blue;
}

.projects_projectsize {
    flex: 1;
}

.projects_versiontext {
    font-size: 14px;
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
}