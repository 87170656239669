.summary {
    display: block;
    background-color: #f3fbff;
    height: 100vh;
}

.summary_columncontainer {
    display: flex;
    flex-direction: row;
}

.summary_column {
    flex: 1;
    margin: 10px;
}

.summary_columntitle {
    color: grey;
    font-weight: 600;
    text-align: center;
}

.summary_columnsection {
    margin-top: 10px;
    background-color: rgb(255, 255, 255);
    border: 1px solid grey;
    border-radius: 5px;
}

.summary_columnsectiontitle {
    font-size: 18px;
    font-weight: 700;
    background-color: #fff2f2;
}

.summary_expenseaccount {
    padding: 5px;
}