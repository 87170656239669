.addproject {
    text-align: center;
    background-color: #f2f8ff;
    width: 100%;
    height: 100vh;
}

.addproject_title {
    margin-top: 20px;
    font-size: 24px;
}