.report {
    text-align: center;
}

.report_title {
    font-size: 24px;
    font-weight: 700;
}

.report_dateframe{
    margin-top: 20px;
}