.inputgroup {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.inputgroup_input {
  width: calc(100% - 32px);
  padding: 8px;
  border: none;
  cursor: pointer;
}

.inputgroup_clearbutton {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #888;
}

.inputgroup_dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  z-index: 1;
}

.inputgroup_dropdowninput {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.inputgroup_dropdownul {
  height: 200px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;
}

.inputgroup_dropdownli {
  font-size: 14px;
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.inputgroup_dropdownli:hover {
  background-color: #f2f2f2;
}