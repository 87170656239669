.header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
}

.header_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.header_section {
    display: flex;
}

.header_logo,
.header_notification {
    cursor: pointer;
    margin-right: 30px;
}

.header_notificationsimage,
.header_settingsimage {
    height: 40px;
    width: 40px;
}

.header_notification {
    border-radius: 10px;
}

.header_notification:hover {
    background-color: #b9b9b9;
}

.header_dropdown {
    position: absolute;
    top: 80px;
    right: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.header_dropdownitem {
    padding: 10px;
    cursor: pointer;
}

.header_dropdownitem:hover {
    background-color: #e0e0e0;
}