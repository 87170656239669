.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.home_projectdetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  background-color: #f5f5f5;
}

.home_projectsection {
  flex: 1;
  text-align: center;
  padding: 10px;
}

.home_projecttext {
  font-size: 18px;
  font-weight: bold;
}

.home_content {
  display: flex;
  flex-direction: row;
}

.home_menu {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 200px;
  background-color: #f7f7f7;
}

.home_button {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  transition: background-color 0.2s;
  text-align: center;
  font-weight: bold;
}

.home_button:hover {
  background-color: #e0e0e0;
}

.home_panel {
  flex: 6;
  padding: 10px;
}