body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.buttonscontainer {
  display: flex;
  justify-content: flex-start;
}

.button {
  background-color: rgb(236, 236, 236);
  border: 1px solid rgb(153, 153, 153);
  color: #000;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.label {
  display: block;
}

.input {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(153, 153, 153);
  color: #7e7e7e;
  padding: 8px 10px;
  border-radius: 3px;
  text-decoration: none;
  font-size: 12px;
  margin: 10px;
}

.select {
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 200px;
  margin: 10px;
  font-size: 12px;
}

.select option {
  background-color: #fff;
  color: #333;
  padding: 10px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table {
  margin-top: 10px;
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
}

.table th {
  border: 1px solid #ddd;
  padding: 6px;
  text-align: left;
  background-color: #f2f2f2;
}

.table td {
  border: 1px solid #ddd;
}

.table td div {
  text-align: left;
  font-weight: 400;
  margin-left: 4px;
}

.table_button {
  background-color: rgb(236, 236, 236);
  border: 1px solid rgb(153, 153, 153);
  color: #000;
  padding: 2px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  margin: 6px 4px;
  cursor: pointer;
  border-radius: 4px;
}

.table_button:hover {
  background-color: #0078a3;
}

.table_sortbutton {
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 10px;
  display: inline-block;
}

.table_sortbutton:hover {
  background-color: #e0dffe;
}

.result {
  margin-top: 10px;
  text-align: center;
  color: red;
  font-weight: 600;
}

/*************************/

.modal {
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow-y: auto;
  display: flex;
}

.modal_overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  background-color: white;
  border: 1px solid black;
  padding: 20px;
  box-sizing: border-box;
}

.modal_content {
  background-color: #fff;
  border-radius: 5px;
  padding: 40px;
  min-width: 600px;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal_head {
  text-align: center;
}

.modal_center {
  text-align: center;
  font-size: 20px;
  color: #007bff;
}

.modal_row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.modal_rowsection {
  flex: 1;
}

.modal_logo {
  height: 43px;
}

.modal_title {
  flex: 1;
  font-size: 30px;
  font-weight: bold;
  color: #333;
  text-align: center;
  text-wrap: nowrap;
}

.modal_subtitle {
  font-size: 18px;
  color: #555;
  text-align: center;
}

.modal_label {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-top: 5px;
}

.modal_input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.modal_textarea {
  width: 100%;
  min-height: 100px;
  resize: vertical;
  box-sizing: border-box;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #333;
  font-size: 16px;
  line-height: 1.6;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: justify;
}

.modal_textrtl {
  direction: rtl;
}

.modal_textltr {
  direction: ltr;
}

.modal_select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 200px;
  overflow: hidden;
}

.modal_select option {
  background-color: #fff;
  color: #333;
  padding: 10px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal_table {
  margin-top: 10px;
  width: 100%;
  border-collapse: collapse;
  font-size: 10px;
}

.modal_table th,
.modal_table td {
  border: 1px solid #ddd;
  padding: 6px;
  text-align: left;
}

.modal_table th {
  background-color: #f2f2f2;
}

.modal_table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.modal_table tfoot {
  font-weight: bold;
  font-size: 16px;
}

.modal_date {
  text-align: center;
  color: #777;
}

.modal_datepicker {
  width: 150px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal_signature {
  border-top: 1px solid black;
}

.modal_result {
  margin-top: 10px;
  text-align: center;
  color: red;
  font-weight: 600;
}

.modal_buttonscontainer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.modal_button {
  width: 120px;
  height: 40px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_button:hover {
  background-color: #0056b3;
}

.modal_cancelbuttoncontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_cancelbutton {
  background-color: #ff9d9d;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .3s ease;
}

.modal_cancelbutton:hover {
  background-color: #8d2100;
}

.projectdetailsimage {
  width: 200px;
  height: 60px;
  margin-top: 20px;
}