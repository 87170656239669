.addjournalentry {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.addjournalentry_content {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 80%;
}

.addjournalentry_title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.addjournalentry_input,
.addjournalentry_select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.addjournalentry_addrowbutton {
    background-color: #4caf50;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 20px;
}

.addjournalentry_buttoncontainer {
    display: flex;
    justify-content: space-between;
}

.addjournalentry_button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.addjournalentry_select {
    width: calc(100% - 22px);
}

.newrow_cancel{
    cursor: pointer;
}